import * as React from 'react'

import { useTheme } from '@mui/material/styles'

import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'

import MenuIcon from '@mui/icons-material/Menu'
import LogoIcon from '@mui/icons-material/Settings'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

import { ColorModeContext } from '../../App'

// const pages = ['Descriere', 'Dotări', 'Noutăți', 'Evenimente']
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']

function ResponsiveAppBar({ config, data }) {
  const theme = useTheme()

  const colorMode = React.useContext(ColorModeContext)

  const pages = data

  const [anchorElNav, setAnchorElNav] = React.useState()
  const [anchorElUser, setAnchorElUser] = React.useState()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <LogoIcon
            color="secondary"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
          /> */}
          <img
            src="/images/SCI-FABLAB-logo.webp"
            alt={config.siteTitle}
            style={{
              // display: { xs: 'none', md: 'flex' },
              maxHeight: 32,
            }}
          />

          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {config.siteTitle}
          </Typography> */}

          {/* <LogoIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}

          {/* <img
            src="/images/SCI-FABLAB-logo.webp"
            alt={config.siteTitle}
            style={{ display: { xs: 'flex', md: 'none' }, maxHeight: 32 }}
          /> */}

          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {config.siteTitle}
          </Typography> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: { xs: 'flex-end' },
            }}
          >
            <IconButton
              size="large"
              sx={{ mr: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon sx={{ transform: 'rotate(180deg)' }} />
              )}
            </IconButton>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => {
                if (JSON.parse(page.display)) {
                  return (
                    <MenuItem
                      key={page.id}
                      onClick={handleCloseNavMenu}
                      // sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      <Typography textAlign="center">
                        {page.itemTitle}
                      </Typography>
                    </MenuItem>
                  )
                }
                return null
              })}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            {pages.map((page) => {
              if (JSON.parse(page.display)) {
                return (
                  <Button
                    key={page.id}
                    onClick={handleCloseNavMenu}
                    sx={{ mx: 1, color: 'white', display: 'block' }}
                  >
                    {page.itemTitle}
                  </Button>
                )
              }
              return null
            })}

            <IconButton
              size="large"
              sx={{ mx: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>

            <Tooltip title="Open settings">
              <IconButton
                size="large"
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                <Avatar alt="User Name" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
