import * as React from 'react'

import { withGoogleSheets } from 'react-db-google-sheets'

import { useTheme, ThemeProvider } from '@mui/material/styles'

import Container from '@mui/material/Container'

import { MainTheme } from './themes/default'

import AppBar from './comps/assets/AppBar'

import Home from './pages/Home'

import './themes/styles/App.css'

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
})

function App({ appData }) {
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)

  const configSource = appData.config
  let config = {}
  for (var i = 0; i < configSource.length; i++) {
    config[configSource[i].item] = configSource[i].itemValue
  }

  // const siteLanguage = config.siteDefaultLanguage

  // console.log(appData)

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        minHeight: '100vh',
        pb: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <AppBar config={config} data={appData.menu} colorMode={colorMode} />

      <Home config={config} />
    </Container>
  )
}

export default withGoogleSheets()(function ToggleColorMode({ db }) {
  const [mode, setMode] = React.useState()
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={MainTheme(mode)}>
        <App appData={db} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
})
