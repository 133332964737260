import React, { useEffect, useState } from 'react'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Events = ({ defaultPadding, config, maxWidth }) => {
  const [eventsData, setEventsData] = useState([])
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {}, [])

  const tmpImages = [
    'Creatie',
    'Electronica',
    'Mecanica',
    'Imprimare 3D',
    'CNC',
    'Laser',
  ]

  return (
    <Container component="section" maxWidth={maxWidth}>
      <Paper
        elevation={1}
        sx={{ mt: defaultPadding, mb: defaultPadding, p: defaultPadding }}
      >
        <Grid container>
          <Grid item md={3}>
            <img
              src="/images/Fablab-Evenimente.webp"
              alt={`${config.siteTitle} - Evenimente`}
              style={{ maxWidth: '100%', borderRadius: 4 }}
            />
          </Grid>
          <Grid
            item
            md={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h2" gutterBottom textAlign={'center'}>
              Evenimente
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
              nostrum eveniet ab modi dolorem eligendi quam pariatur, officiis
              iusto nobis reiciendis natus perferendis beatae tempore officia
              voluptatibus placeat commodi dolores.
            </Typography>

            {[1, 2, 3, 4].map((event, idx) => (
              <Accordion
                elevation={0}
                expanded={expanded === `panel${idx}`}
                onChange={handleChange(`panel${idx}`)}
                style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid container>
                    <Grid item md={2} display="flex" justifyContent="center">
                      <img
                        src={`/images/Fablab-Logo-${event}---${
                          tmpImages[event - 1]
                        }.webp`}
                        alt={`${config.siteTitle} - Evenimente`}
                        style={{
                          maxWidth: '100%',
                          maxHeight: 128,
                          borderRadius: 4,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={10}
                      display="flex"
                      // alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography
                        component="h3"
                        variant="h5"
                        gutterBottom
                        // textAlign={'center'}
                      >
                        Event #{idx} Title
                      </Typography>
                      <Typography>
                        Nulla facilisi. Phasellus sollicitudin nulla et quam
                        mattis feugiat. Aliquam eget maximus est, id dignissim
                        quam.
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                    nostrum eveniet ab modi dolorem eligendi quam pariatur,
                    officiis iusto nobis reiciendis natus perferendis beatae
                    tempore officia voluptatibus placeat commodi dolores.
                  </Typography>
                  <Button
                    // variant="link"
                    color="quaternary"
                    // color="primary"
                    sx={{
                      display: 'flex',
                      mt: 2,
                      marginLeft: 'auto',
                      mr: 0,
                    }}
                  >
                    Detalii
                  </Button>
                </AccordionDetails>
              </Accordion>
            ))}
            <div
              style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            ></div>
            <Button
              // variant="link"
              color="secondary"
              sx={{
                display: 'flex',
                margin: '0 auto !important',
                marginTop: '16px !important',
              }}
            >
              View More
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 6 }}>
          <Grid item md={3}>
            <img
              src="/images/Fablab-Activitati.webp"
              alt={`${config.siteTitle} - Activitati`}
              style={{ maxWidth: '100%', borderRadius: 4 }}
            />
          </Grid>
          <Grid
            item
            md={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              component="h3"
              variant="h4"
              gutterBottom
              textAlign={'center'}
            >
              FabLab în acțiune
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
              nostrum eveniet ab modi dolorem eligendi quam pariatur, officiis
              iusto nobis reiciendis natus perferendis beatae tempore officia
              voluptatibus placeat commodi dolores.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default Events
