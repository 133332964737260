import React, { useEffect, useState } from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const Contact = ({ defaultPadding, config, maxWidth, contentWidth }) => {
  const [contactData, setContactData] = useState([])

  useEffect(() => {}, [])

  return (
    <Container component="section" maxWidth={maxWidth} disableGutters>
      <AppBar
        component="div"
        position="relative"
        // color="primary"
        elevation={1}
        sx={{
          mt: defaultPadding,
          mb: defaultPadding * 2,
        }}
      >
        <Container component="section" maxWidth={contentWidth} disableGutters>
          <Box
            sx={{
              minHeight: 256,
              p: defaultPadding,
            }}
          >
            <Toolbar
              disableGutters
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <img
                src="/images/Fablab-Contact.webp"
                alt={`${config.siteTitle} - ontact`}
                style={{ maxWidth: '25%', borderRadius: 4 }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="center"
                >
                  Adresa: 407 Atomistilor St. Magurele 077125, Ilfov, Romania
                  Et. 3, Camera 302
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="center"
                >
                  E-mail: scifablabmagurele@gmail.com
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="center"
                >
                  Harta Google Maps interactivă
                </Typography>
              </Box>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
    </Container>
  )
}

export default Contact
