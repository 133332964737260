import React, { useEffect, useState } from 'react'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const Footer = ({ defaultPadding, config, maxWidth }) => {
  const [footerData, setFooterData] = useState([])

  const currentYear = new Date().getFullYear()

  useEffect(() => {}, [])

  return (
    <Container component="footer" maxWidth={maxWidth} disableGutters>
      <Paper
        elevation={0}
        sx={{
          // mt: defaultPadding / 2,
          // mb: defaultPadding / 2,
          p: defaultPadding / 2,
          backgroundColor: 'transparent',
        }}
      >
        <Typography variant="caption" component="div" textAlign="center">
          <span>Copyright &copy; {currentYear} </span>
          <Button
            size="small"
            color="inherit"
            variant="text"
            href={config.siteCopyrightOwnerURL}
            target="_blank"
          >
            {config.siteCopyrightOwner}
          </Button>
          <br />
          <span>Toate drepturile rezervate.</span>
        </Typography>
      </Paper>
    </Container>
  )
}

export default Footer
