import React from 'react'
import {
  StyledEngineProvider,
  // LinearProgress,
  CircularProgress,
  // ThemeProvider,
  alpha,
} from '@mui/material'
import { useTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { MainTheme } from '../../themes/default'

const CustomizedCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '&.circularProgress': {
    width: `${theme.spacing(24)} !important`,
    height: `${theme.spacing(24)} !important`,
    // color: theme.palette.background.ces,
  },
  '& .circularProgressCircle': {
    strokeWidth: 0.75,
    fill: alpha(theme.palette.primary.main, 0.25),
  },
  '& + .cesLogoLoading': {
    position: 'absolute',
    maxWidth: 128,
    marginTop: -4,
    marginLeft: -2,
  },
}))

export default function LinearDeterminate({ variant }) {
  const theme = useTheme()
  // const classes = useStyles()
  // eslint-disable-next-line
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 15)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <ThemeProvider theme={MainTheme()}>
      {' '}
      <StyledEngineProvider injectFirst>
        <div className="circularProgressWrapper">
          {/* <LinearProgress variant={variant} value={progress} /> */}
          <CustomizedCircularProgress
            classes={{
              root: 'circularProgress',
              circle: 'circularProgressCircle',
            }}
          />
          <img
            src="/images/SCI-FABLAB-logo.webp"
            alt="Loading..."
            className="cesLogoLoading"
          />
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}
