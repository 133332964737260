import React from 'react' // useEffect, useState

import { useTheme } from '@mui/material/styles'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import Dotari from './sections/Dotari'
import Noutati from './sections/Noutati'
import Events from './sections/evenimente'
import Contact from './sections/contact'
import Footer from '../comps/pages/footer'

const Home = ({ config, data }) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)

  const defaultPadding = 3
  const maxWidth = false
  // const [HomeData, setHomeData] = useState([])

  // useEffect(() => {}, [])

  // console.log(config)

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 0, mb: defaultPadding, p: 0 }}
      >
        <img
          src="/images/SciFabLab-Macgurele-Hero.webp"
          alt={config.siteTitle}
          style={{ width: '100%', maxWidth: '1600px' }}
        />
      </Box>
      <Container maxWidth="lg" sx={{ mt: defaultPadding * 1.5 }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ minWidth: '100%' }}
          >
            <AlertTitle>Prototype</AlertTitle>
            <strong>{theme.palette.mode}</strong> mode
          </Alert>
        </Collapse>

        <Paper
          elevation={0}
          sx={{
            mt: defaultPadding,
            mb: defaultPadding,
            p: defaultPadding,
            backgroundColor: 'transparent',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h1"
            gutterBottom
            textAlign={'center'}
            sx={{ mt: 6 }}
          >
            {config.siteTitle}
          </Typography>
          <Typography
            component="p"
            variant="h5"
            gutterBottom
            textAlign={'center'}
            sx={{ mt: 6 }}
          >
            Scientific Fablab Măgurele - Comunitatea „Educație pentru Știință”
          </Typography>
          <Button variant="contained">Primary Color</Button>
          <Button variant="contained" color="secondary">
            Secondary Color
          </Button>
          <Button variant="contained" color="tertiary">
            Tertiary Color
          </Button>
          <Button variant="contained" color="quaternary">
            Quaternary Color
          </Button>
        </Paper>

        <Paper
          elevation={0}
          color="secondary"
          sx={{ mt: defaultPadding, mb: defaultPadding, p: defaultPadding }}
        >
          {/* <Typography variant="h2" gutterBottom textAlign={'center'}>
            Descriere
          </Typography>

          <img
            src="/images/Fablab - Evenimente.jpg"
            alt={`${config.siteTitle} - Descriere`}
            style={{ maxWidth: '100%', borderRadius: 4 }}
          /> */}

          <Typography variant="subtitle1" gutterBottom>
            FabLab-urile sunt o rețea globală de laboratoare locale, care
            deschid oportunități de inovare în domenii diverse oferind acces la
            instrumente pentru o fabricare digitală rapidă. În special, Sci
            FabLab Magurele , inițiativă a CEST (Comunității Educație pentru
            Știință), este o zonă de atelier care oferă posibilitatea de
            prototipare a tehnologiei în care învățarea, experimentarea,
            inovația și invenția sunt încurajate prin curiozitate, creativitate,
            realizarea hands-on și, în mod crucial, prin împărtășirea deschisă a
            cunoștințelor.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Fiecare Fablab folosește o rețetă comună de are un set comun de
            instrumente și procese, astfel încât puteți învăța cum să lucrați
            într-un Fablab aici, în Măagurele, de exemplu, și să folosiți aceste
            abilități în oricare dintre cele, mai mult de 1000 de laboratoare de
            pe glob. A fi Fablab înseamnă a te conecta la o comunitate globală
            și națională de studenți, educatori, tehnologi, cercetători,
            creatori și inovatori care contribuie la o rețea deschisă/gratuită,
            de schimb de cunoștințe.
          </Typography>
        </Paper>
      </Container>

      <Dotari config={config} defaultPadding={defaultPadding} maxWidth={'lg'} />
      <Noutati
        config={config}
        defaultPadding={defaultPadding}
        maxWidth={'lg'}
      />
      <Events config={config} defaultPadding={defaultPadding} maxWidth={'lg'} />
      <Contact
        config={config}
        defaultPadding={defaultPadding}
        maxWidth={maxWidth}
        contentWidth={'lg'}
      />

      <Footer
        config={config}
        defaultPadding={defaultPadding}
        maxWidth={maxWidth}
        contentWidth={'lg'}
      />
    </>
  )
}

export default Home
