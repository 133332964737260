import React, { useEffect, useState } from 'react'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const Dotari = ({ defaultPadding, config, maxWidth }) => {
  const [DotariData, setDotariData] = useState([])

  useEffect(() => {}, [])

  return (
    <Container component="section" maxWidth={maxWidth}>
      <Paper
        elevation={1}
        sx={{ mt: defaultPadding, mb: defaultPadding, p: defaultPadding }}
      >
        <Typography variant="h2" gutterBottom textAlign={'center'}>
          Dotări
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={6}
        >
          {[
            'Creatie',
            'Electronica',
            'Mecanica',
            'Imprimare 3D',
            'CNC',
            'Laser',
          ].map((img, idx) => (
            <Grid item xs={6} sm={4} key={idx}>
              <img
                src={`/images/Fablab-Logo-${idx + 1}---${img}.webp`}
                alt={`${config.siteTitle} - Dotări - ${img}`}
                style={{ maxWidth: '100%' }}
              />
              <Typography
                component="div"
                variant="h6"
                textAlign={'center'}
                gutterBottom
              >
                {img}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Typography gutterBottom>
          O înșiruire orizontală a celor 6 zone (Creație, Electronică, Mecanică,
          Printare 3D, CNC si Laser) componente constitutive - Realizate grafic
          de către Mihai Folea. Acestea vor fi color sau alb-negru în funcție de
          nivelul de implementarea și cand se va da click pe ele se va deschide
          un pop-up simplu cu cateva detalii concrete despre dotările fiecare
          zone (la fel editabile de către utilizator autentificat) Pop-up-ul
          dispare la click în afara lui.
        </Typography>
        <Typography gutterBottom>
          Un filmulet de tranziție în loop:
          https://photos.app.goo.gl/b1bwEK2fzvMCxott8
        </Typography>
      </Paper>
    </Container>
  )
}

export default Dotari
