import React from 'react'
import ReactDOM from 'react-dom/client'

import { StyledEngineProvider } from '@mui/material/styles'

import GoogleSheetsProvider from 'react-db-google-sheets'

import reportWebVitals from './reportWebVitals'

import Loading from './comps/custom-react-db-google-sheets/mui-progress'
import App from './App'

import './themes/styles/main.css'

const config = {
  dataLoading: {
    component: Loading,
  },
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <GoogleSheetsProvider config={config}>
        <App />
      </GoogleSheetsProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
