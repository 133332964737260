import React, { useEffect, useState } from 'react'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const Noutati = ({ defaultPadding, config, maxWidth }) => {
  const [NoutatiData, setNoutatiData] = useState([])

  useEffect(() => {}, [])

  return (
    <Container component="section" maxWidth={maxWidth}>
      <Paper
        elevation={0}
        sx={{ mt: defaultPadding, mb: defaultPadding, p: defaultPadding }}
      >
        <Grid container>
          <Grid item md={3}>
            <img
              src="/images/Fablab-Noutati.webp"
              alt={`${config.siteTitle} - Noutăți`}
              style={{ maxWidth: '100%', borderRadius: 4 }}
            />
          </Grid>
          <Grid
            item
            md={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h2" gutterBottom textAlign={'center'}>
              Noutăți
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
              nostrum eveniet ab modi dolorem eligendi quam pariatur, officiis
              iusto nobis reiciendis natus perferendis beatae tempore officia
              voluptatibus placeat commodi dolores.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default Noutati
