import React from 'react'
import { createTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// import { dark } from '@material-ui/core/styles/createPalette'
// import amber from '@mui/material/colors/amber'
import tertiary from '@mui/material/colors/deepOrange'

function Theme(mode) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  // console.log('Theme', mode)

  return React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode && mode ? mode : 'dark',
          primary: {
            main: '#662d91',
            light: '#8457a7',
            dark: '#471f65',
            contrastText: '#fff',
          },
          secondary: {
            main: '#94c11f',
            light: '#a9cd4b',
            dark: '#678715',
            // contrastText: '',
          },
          tertiary: {
            main: tertiary['A700'],
            light: tertiary[700],
            dark: tertiary[900],
            contrastText: '#fff',
          },
          quaternary: {
            main: '#2196f3',
            light: '#4dabf5',
            dark: '#1769aa',
            contrastText: '#e6f1d9',
          },
          background: {
            default: prefersDarkMode
              ? mode === 'light'
                ? '#e6f1d9'
                : '#121314'
              : '#121314',
            paper: prefersDarkMode
              ? mode === 'light'
                ? '#eef7e7'
                : '#121314'
              : '#121314',
            ces: '#a0e84c',
          },
          // Used by `getContrastText()` to maximize the contrast between
          // the background and the text.
          contrastThreshold: 3,
          // Used by the functions below to shift a color's luminance by approximately
          // two indexes within its tonal palette.
          // E.g., shift from Red 500 to Red 300 or Red 700.
          tonalOffset: 0.2,
        },
        shape: {
          verticalSpacer: 4,
        },
      }),
    [prefersDarkMode, mode],
  )
}

// console.log(theme.palette.background)

function MainTheme(mode) {
  // const [themeMode, setMode] = React.useState(mode)
  const theme = Theme(mode)
  // console.log('MainTheme', mode)
  return {
    ...theme,
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            color: theme.palette.common.white,
          },
          positionFixed: {
            top: theme.spacing(3),
            right: theme.spacing(3),
            left: theme.spacing(3),
            width: `calc(100vw - ${theme.spacing(8)})`,
            [theme.breakpoints.between('md', 'lg')]: {
              width: `calc(100vw - ${theme.spacing(6)})`,
            },
            [theme.breakpoints.between('sm', 'md')]: {
              width: `calc(100vw - ${theme.spacing(6)})`,
            },
            [theme.breakpoints.only('xs')]: {
              top: theme.spacing(theme.shape.verticalSpacer / 2),
              right: theme.spacing(theme.shape.verticalSpacer / 2),
              left: theme.spacing(theme.shape.verticalSpacer / 2),
              width: `calc(100vw - ${theme.spacing(
                theme.shape.verticalSpacer,
              )})`,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            marginRight: theme.spacing(1),
            // color: theme.palette.common.white,
            '&:last-child': {
              marginRight: 0,
            },
          },
          // contained: {
          //   backgroundColor: theme.palette.tertiary.main,
          //   color: theme.palette.primary.main,
          //   fontWeight: 600,
          //   '&:hover': {
          //     backgroundColor: theme.palette.tertiary.dark,
          //   },
          // },
          containedPrimary: {
            fontWeight: 500,
          },
          containedSecondary: {
            fontWeight: 500,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h3: {
            '&$gutterBottom': { margin: theme.spacing(6, 0, 4) },
          },
          h4: {
            margin: theme.spacing(6, 0, 4),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 0,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(0.5),
          },
          label: {
            fontWeight: 500,
          },
        },
      },
    },
  }
}

export { Theme, MainTheme }
